import React, { useState,  ChangeEvent } from 'react';
import { MuiTelInput } from 'mui-tel-input';
import { Button, TextField, CircularProgress, Typography } from '@mui/material';
import { createClient } from '@supabase/supabase-js'
import { usePostHog } from 'posthog-js/react'

const supabase = createClient(
  process.env.REACT_APP_PUBLIC_SUPABASE_URL ?? "",
  process.env.REACT_APP_PUBLIC_SUPABASE_ANON_KEY ?? "",
);

const App = () => {
  const posthog = usePostHog()
  const [step, setStep] = useState(1);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [loading, setLoading] = useState(false);

  const handlePhoneNumberChange = (value: string) => {
    setPhoneNumber(value);
  };

  const handleVerificationCodeChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    e.preventDefault();
    if (e.target.value.length <= 6) {
      setVerificationCode(e.target.value);
    }
  };

  const handleSubmitPhoneNumber = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setLoading(true);
    const phone = phoneNumber.replaceAll(' ', '');
    const {  error } = await supabase.auth.signInWithOtp({
      phone,
    });
    if (error) {
      alert(error.message);
      console.error(error);
    } else {
      setStep(2);
    }
    setLoading(false);
  };

  const handleSubmitVerificationCode = async () => {
    setLoading(true);
    // get query params
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const duration = urlParams.get('duration');

    const phone = phoneNumber.replaceAll(' ', '');
    const { error } = await supabase.auth.verifyOtp({ phone, token: verificationCode, type: 'sms'});
    const { data } = await supabase.functions.invoke('get_user_stripe_link', {
      body: {
        alias: posthog.get_distinct_id(),
        duration: duration,
        return_url: window.location.origin,
        success_url: window.location.origin,
        cancel_url: window.location.origin,
      }
    });
    if (data.redirect_url) {
      window.location.assign(data.redirect_url);
    }
    if (error) {
      alert(error.message);
      console.error(error);
      return;
    }
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <div style={{ width: '400px', paddingTop: '40px', paddingBottom: '40px' }}>
        <Typography variant="h4" gutterBottom>
          Payments Page
        </Typography>
        <Typography variant="subtitle1" gutterBottom style={{marginBottom: "16px"}}>
          Log in to subscribe or view your customer portal.
        </Typography>
        <MuiTelInput
          value={phoneNumber}
          label="Phone Number"
          placeholder="+1XXXXXXXXXX"
          onChange={handlePhoneNumberChange}
          defaultCountry="US"
          fullWidth
          preferredCountries={['US', 'CA']}
        />
        <div style={{ textAlign: 'center', marginTop: '16px' }}>
          {step === 1 && phoneNumber.length > 0 && (
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmitPhoneNumber}
              disabled={loading}
            >
              {loading ? (
                <>
                  <CircularProgress size={20} style={{ marginRight: '8px' }} />Sending Code...
                </>
              ) : (
                'Log In'
              )}
            </Button>
          )}
        </div>
        {step === 2 && (
          <>
            <TextField
              value={verificationCode}
              onChange={handleVerificationCodeChange}
              label="Verification Code"
              placeholder="XXXXXX"
              type="number"
              fullWidth
              inputProps={{ maxLength: 6 }}
              variant="outlined"
              style={{ marginTop: '16px' }}
            />
            <div style={{ textAlign: 'center', marginTop: '16px' }}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleSubmitVerificationCode}
                disabled={loading}
              >
                {loading ? (
                  <>
                    <CircularProgress size={20} style={{ marginRight: '8px' }} />Redirecting...
                  </>
                ) : (
                  'Verify'
                )}
              </Button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default App;

